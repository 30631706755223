import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';

const Layout: React.FC = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Navigation />
      <main className="flex-grow container mx-auto py-8">
        <Outlet />
      </main>
      <footer className="bg-gray-900 text-white text-center py-4">
        <p>&copy; 2024 chrisjohnson.net. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;