import React from 'react';

interface dataVizData {
    title: string;
    imageUrl: string;
    description?: string; // optional description
    link?: string; // optional link
}

const dataViz: dataVizData[] = [
    {
        title: 'Lucas Oil Stadium',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/stadium1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/stadium.jpg',
    },
    {
        title: 'Building Damage',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/building_damage1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/building_damage.jpg',
    },
    {
        title: 'Knee Surgery',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/cartilage1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/cartilage.jpg',
    },
    {
        title: 'Dolphin Exhibit',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/dolphin1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/dolphin.jpg',
    },
    {
        title: 'Indianapolis Museum of Art',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/IMA1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/IMA.jpg',
    },
    {
        title: 'Prison',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/prison1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/prison.jpg',
    },
    {
        title: 'Rhino Exhibit',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/rhino1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/rhino.jpg',
    },
    {
        title: 'Toxic',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Toxic1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Toxic.jpg',
    },
    {
        title: 'Meinrad',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Meinrad1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Meinrad.jpg',
    },
    {
        title: 'Runoff',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/runoff1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/runoff.jpg',
    },
    {
        title: 'Hit and Run',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/hitrun1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/hitrun.jpg',
    },
    {
        title: 'Bridge',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Bridge1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/DrawBridge.gif',
    },
    {
        title: 'Boat Crash',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Crash1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Crash.jpg',
    },
    {
        title: 'Bridge Crane',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/bridgecrane1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/bridgecrane.jpg',
    },
    {
        title: 'Seat Belts',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/seatbelt1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/seatbelt.jpg',
    },
    {
        title: 'House',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/House1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/House.jpg',
    },
    {
        title: 'Plane Crash',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/apcrash1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/apcrash.jpg',
    },
    {
        title: 'Train',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/train1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/train.jpg',
    },
    {
        title: 'Car Crash',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/bccrash1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/bccrash.jpg',
    },
    {
        title: 'Bar Fire',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/lofire1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/lofire.jpg',
    },
    {
        title: 'Ergonomics',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Ergonomics1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Ergonomics.jpg',
    },
    {
        title: 'Marlins',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Marlins1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Marlins.jpg',
    },
    {
        title: 'Electrocution',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/electro1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/electro.jpg',
    },
    {
        title: 'Telemarketers',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/telemark1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/telemark.jpg',
    },
    {
        title: 'Dive',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/dive1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/dive.jpg',
    },
    {
        title: 'Firewalls',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/firewall1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/firewall.jpg',
    },
    {
        title: 'Malaria',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Malaria1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Malaria.jpg',
    },
    {
        title: 'Electronic Nose',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Nose1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Nose.jpg',
    },
    {
        title: 'K2',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/K21.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/K2.jpg',
    },
    {
        title: 'Fireworks',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Fireworks1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Fireworks.jpg',
    },
    {
        title: 'New Arena',
        imageUrl: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Thumbnails/Narena1.jpg',
        link: 'http://www.chrisjohnson.net/chrisjohnson.net/graphics/Originals/Narena.jpg',
    }
];

const DataVizItem: React.FC<dataVizData> = ({ title, imageUrl, description, link }) => {
    return (
        <div key={title} className="bg-white rounded-lg border shadow-md sm:max-w-xs md:max-w-none overflow-hidden max-w-none p-5">
            <a className="mt-3 block text-blue-500" href={link} target="_blank">
                <img className="h-30 lg:h-30 w-full object-cover mb-5 border mb-2" src={imageUrl} alt={title} />
            </a>
            <div className="">
                <h3 className="font-semibold text-xl leading-6 text-gray-900 my-2">
                    {title}
                </h3>
                {description && <p className="paragraph-normal text-gray-600">{description}</p>}
                {link && <a className="mt-3 block text-blue-500" href={link} target="_blank">Read More </a>}
            </div>
        </div>
    );
};

const DataVizWork: React.FC = () => {
    const items = ['item1', 'item2', 'item3', 'item4', 'item5'];
    return (
        <section className="max-w-6xl mx-auto">

            <h1 className="text-3xl font-bold mb-6">Interactive Data</h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                {dataViz.map((interactive) => (
                    <DataVizItem key={interactive.title} {...interactive} />
                ))}

            </div>
        </section>
    );
};

export default DataVizWork;



