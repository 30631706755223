import React from 'react';

const CensusMap: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Technical Description of Census Data Visualization Project</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <img src="https://via.placeholder.com/150" alt="Your Name" className="rounded-full mx-auto mb-4" />

        <p className="text-gray-700 mb-4">
        Project Title: Dynamic Census Data Visualization Map
        </p>

        <p className="text-gray-700 mb-4">
        Year: 2011
        </p>
        <p className="text-gray-700 mb-4">
        Technologies Used: PHP, JavaScript, jQuery, MySQL, HTML, CSS, Shapefiles, Google Maps API
        </p>
        <p className="text-gray-700 mb-4">
        Project Overview:
        </p>
        <p className="text-gray-700 mb-4">
        Developed a dynamic web application to visualize census data through an interactive map interface. The application allows users to explore and analyze demographic information released by the Census Bureau, providing insights into population trends and geographic distributions.
        </p>

        <p className="text-gray-700 mb-4">
        Key Features:
        </p>
        <p className="text-gray-700 mb-4">
        Dynamic Mapping: Utilized the Google Maps API to render a large-scale map that displays census data visually. Users can interact with the map to view specific data points and demographic information.
        </p>
        <p className="text-gray-700 mb-4">
        Data Processing: Implemented PHP scripts (get_data.php, process_new.php) to handle data retrieval and processing. The application integrates with a MySQL database to store and manage census data efficiently.
        </p>
        <p className="text-gray-700 mb-4">
        Shapefile Integration: Incorporated shapefiles for geographic boundaries, allowing for accurate representation of census tracts. Used custom PHP classes (ShapeFile.inc.php, PolylineReducer.php) to parse and manipulate shapefile data.
        </p>

        <p className="text-gray-700 mb-4">
        User Interaction: Enhanced user experience with jQuery and jQuery UI for dynamic content updates and interactive elements, such as tooltips and dialogs.</p>
        <p className="text-gray-700 mb-4">Data Visualization: Leveraged Google Charts and custom rendering scripts (renderX.php) to create visual representations of data, enabling users to analyze trends and patterns effectively.</p>
        <p className="text-gray-700 mb-4">Responsive Design: Ensured the application is accessible on various devices by implementing responsive design principles in HTML and CSS.</p>
        <p className="text-gray-700 mb-4">Challenges Overcome:</p>
        
        <p className="text-gray-700 mb-4">
        Developed a robust data processing pipeline to handle large datasets efficiently, ensuring quick load times and smooth user interactions.</p>
        <p className="text-gray-700 mb-4">Addressed cross-browser compatibility issues by utilizing jQuery and thorough testing across different platforms.</p>
        
        <p className="text-gray-700 mb-4">Outcome:</p>

        <p className="text-gray-700 mb-4">
        The project successfully provided an engaging platform for users to explore census data, contributing to a better understanding of demographic changes over time. The application was well-received for its intuitive interface and comprehensive data visualization capabilities.
        </p>

      </div>
    </div>
  );
};

export default CensusMap;