import React from 'react';

const JobHistory: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Job History</h1>
      <div className="space-y-6">
        <div className="border-l-4 border-blue-500 pl-4">
          <h2 className="text-2xl font-semibold">Senior Software Engineer</h2>
          <p className="text-gray-600">Company Name | 2020 - Present</p>
          <ul className="list-disc list-inside mt-2">
            <li>Led development of key features for flagship product</li>
            <li>Mentored junior developers and conducted code reviews</li>
            <li>Implemented CI/CD pipelines to improve deployment efficiency</li>
          </ul>
        </div>
        <div className="border-l-4 border-green-500 pl-4">
          <h2 className="text-2xl font-semibold">Software Developer</h2>
          <p className="text-gray-600">Previous Company | 2017 - 2020</p>
          <ul className="list-disc list-inside mt-2">
            <li>Developed and maintained multiple web applications</li>
            <li>Collaborated with cross-functional teams to deliver projects on time</li>
            <li>Optimized database queries, improving application performance by 30%</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JobHistory;