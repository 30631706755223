import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="text-2xl font-bold">Chris Johnson</Link>
          <div className="hidden md:flex space-x-12 md:space-x-8">
            <Link to="/resume" className="hover:text-gray-300">Resume</Link>
            <Link to="/data-viz" className="hover:text-gray-300">Data Visualization</Link>
            <Link to="/ai" className="hover:text-gray-300">Artificial Intelligence</Link>
            <Link to="/interactive-work" className="hover:text-gray-300">Interactive</Link>
            <Link to="/about" className="hover:text-gray-300">About</Link>
          </div>
          <button onClick={toggleMenu} className="md:hidden focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden bg-gray-700`}>
        <Link to="/resume" className="block px-4 py-2 hover:bg-gray-600">Resume</Link>
        <Link to="/job-history" className="block px-4 py-2 hover:bg-gray-600">Job History</Link>
        <Link to="/ai" className="block px-4 py-2 hover:bg-gray-600">AI</Link>
        <Link to="/interactive-work" className="block px-4 py-2 hover:bg-gray-600">Interactive Work</Link>
        <Link to="/about" className="block px-4 py-2 hover:bg-gray-600">About</Link>
      </div>
    </nav>
  );
};

export default Navigation;