import React from 'react';
import { FaRobot, FaEye, FaComments, FaArrowRight, FaBalanceScaleRight } from 'react-icons/fa';

const AIColabs: React.FC = () => {
  // https://react-icons.github.io/react-icons/icons/fa/
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold mb-2">Artificial Intelligence</h1>
      <p className="text-gray-700 mb-8">A collection of Google Colabs that I have found to be very helpful in understanding AI Concepts.</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-semibold mb-2 flex items-center">
            <FaRobot className="mr-2 text-blue-500" />
            RouteLLM
          </h2>
          <p className="text-gray-600 mb-4">Framework for routing simpler AI queries to cheaper models.</p>
          <a href="https://colab.research.google.com/drive/10MhJ82yIDOl2wdW-HxIQg9Vp9iQGnOaJ?usp=sharing" className="text-blue-500 hover:underline flex items-center" target="_blank">
            Open Notebook <FaArrowRight className="ml-1" />
          </a>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-semibold mb-2 flex items-center">
            <FaBalanceScaleRight className="mr-2 text-blue-500" />
            Neural Network 
          </h2>
          <p className="text-gray-600 mb-4">A gathering of tests, examples and explanations.</p>
          <a href="https://colab.research.google.com/drive/1Wyyydwl1zyaOa7JgfvBEjS-fh6tKHgEx?usp=sharing" className="text-blue-500 hover:underline flex items-center">
            Open Notebook <FaArrowRight className="ml-1" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AIColabs;
