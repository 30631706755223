import React from 'react';

const Resume: React.FC = () => {
  return (
    <div className="bg-gray-100 text-gray-800">
      <div className="container mx-auto py-10 px-5">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
          <div className="p-4 sm:p-6">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-3xl font-bold text-blue-600">Chris Johnson</h2>
                <p className="text-sm text-gray-600">Full-Stack Developer, AI Engineer, Designer, Data Nerd</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Phone: (502) 909-0968</p>
                <p className="text-sm text-gray-600">Email: kutyadog@gmail.com</p>
                <p className="text-sm text-gray-600">Location: Louisville, KY 40205</p>
              </div>
            </div>
            <hr className="my-4" />
            <div>
              <h3 className="text-lg font-semibold mb-2">Experience</h3>
              <div className="space-y-4">
                <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-md font-semibold">The Washington Post</h4>
                  <p className="text-sm text-gray-600">Senior Software Engineer | Dec 2018 - Dec 2023</p>
                  <ul className="list-disc list-inside text-sm">
                    <li>Conceptualized and quickly built AI proof of concept projects which included:</li>
                    <ul className="list-disc list-inside pl-4">
                      <li>
                        Internal HR chatbot:
                        <ul className="list-disc list-inside pl-4">
                          <li>Python (backend): pandas, gradio</li>
                          <li>React (frontend)</li>
                          <li>RAG (Retrieval Augmented Generation)</li>
                          <li>
                            Semantic Search (smart search): Created vector embeddings of source content,
                            implemented efficient search algorithms to identify and retrieve the top relevant data
                            to use as context.
                          </li>
                          <li>
                            Queried OpenAI's API to generate context-aware responses, enhancing the chatbot's
                            accuracy and relevance (also tested with various open source LLMs such as llama2).
                          </li>
                          <li>
                            User Feedback Mechanism: Introduced thumbs up/down scoring on the React frontend to
                            flag user feedback, enabling instant refinement of system responses.
                          </li>
                          <li>
                            Content Management System: Built a CMS for content owners to manage source data,
                            enforcing standards and ensuring that updates are instantly reflected on the frontend.
                          </li>
                        </ul>
                      </li>
                      <li>
                        SEO analysis tool - I built a POC that leveraged metadata, SEO optimization standards,
                        and Google Trends data to bolster the SEO performance of stories.
                        <ul className="list-disc list-inside pl-4">
                          <li>Python - gradio, openai, pytrends, etc.</li>
                          <li>Project launched July 2024 - From the project manager: project is ‘largely in-line
                            with the work you did and people are very excited about it’ (reference available)</li>
                        </ul>
                      </li>
                      <li>
                        Sentiment Analysis, semantic search, fine-tuning open source LLMs (llama2, phi-3-mini,
                        etc.), image classification with TensorFlow, developed predictive models using linear
                        regression
                      </li>
                      <li>
                        Experience with prompt refining, function calling, fine-tuning, Retrieval-Augmented
                        Generation (RAG) with both OpenAi's API and open-source LLMs. Used React, React Test
                        Library, and TypeScript for incremental improvements and the development of new components
                        and templates for emerging news sections, microsites, etc. Engineered and integrated
                        features using Java and JSP within the Posts proprietary web publishing system, PageBuilder,
                        contributing to both incremental improvements and the development of new components and
                        templates for emerging news sections.
                      </li>
                    </ul>
                  </ul>
                </div>
                <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-md font-semibold">The Washington Post</h4>
                  <p className="text-sm text-gray-600">Senior Product Developer / Designer | Sep 2014 - Dec 2018</p>
                  <ul className="list-disc list-inside text-sm">
                    <li>
                      Spearheaded the "Enterprise Template" initiative to enhance online storytelling through increased design
                      flexibility. At the time, there was really only one online template for designers to use and it offered very
                      little in the way of creativity and variety. The Enterprise Template changed that.
                    </li>
                    <li>
                      Collaborated closely with editors and designers to understand storytelling needs and translated them into
                      technical solutions, enriching the digital narrative experience.
                    </li>
                    <li>
                      Developed a suite of WordPress plugins using PHP, JavaScript, CSS, and HTML to introduce custom design
                      elements and integrated legacy content systems for seamless story integration.
                    </li>
                    <li>
                      Engineered a robust content delivery framework within the PageBuilder environment, utilizing Java and JSP,
                      which dynamically rendered feature-rich, custom-designed articles from WordPress-generated JSON data in
                      the new PageBuilder 'enterprise template', enabling a previously unparalleled level of creative freedom
                      for The Washington Post's design team.
                    </li>
                  </ul>
                </div>
                <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
                  <h4 className="text-md font-semibold">MediaNews Group</h4>
                  <p className="text-sm text-gray-600">Director, Mobile Product Development | Jul 2011 - Jun 2014</p>
                  <ul className="list-disc list-inside text-sm">
                    <li>
                      Orchestrated innovative mobile initiatives across DFM's portfolio of 120+ newspapers, encompassing strategic
                      planning, technical development, and project management.
                    </li>
                    <li>
                      Pioneered an HTML5/Ajax mobile web application framework, elevating the mobile presence of all DFM
                      newspapers (including Denver Post, Mercury News, SiliconValley.com), resulting in significant traffic and
                      revenue growth.
                    </li>
                    <li>
                      Advocated for and executed the in-house development of a proprietary mobile solution, effectively
                      transitioning away from a cost-prohibitive third-party vendor, culminating in over $648,000 in savings per year.
                    </li>
                    <li>
                      Integrated core systems such as Akamai for web acceleration, Google's DoubleClick for Publishers (DFP)
                      for ad integration for mobile initiatives.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Skills</h3>
                <div className="grid grid-cols-4 gap-8">
                  <ul className="list-disc list-inside text-sm">
                    <li>React.js</li>
                    <li>Next.js</li>
                    <li>Express.js</li>
                    <li>Node</li>
                    <li>Docker</li>
                    <li>AWS Cloud Services (ECS clusters, Lambda functions, etc.)</li>
                    <li>React Test Library</li>
                    <li>Object Oriented Programming</li>
                  </ul>
                  <ul className="list-disc list-inside text-sm">
                    <li>REST APIs</li>
                    <li>Javascript</li>
                    <li>Python</li>
                    <li>PHP</li>
                    <li>SQL</li>
                    <li>TypeScript</li>
                    <li>Java</li>
                    <li>OpenAI API</li>
                  </ul>
                  <ul className="list-disc list-inside text-sm">
                    <li>Data Analysis</li>
                    <li>Search Algorithms</li>
                    <li>Data Processing</li>
                    <li>Prompt Refining</li>
                    <li>Google Colab</li>
                    <li>Notebooks</li>
                    <li>LLM Function Calling</li>
                    <li>LangChain</li>
                    <li>Ollama</li>
                    <li>RouteLLM</li>
                  </ul>
                  <ul className="list-disc list-inside text-sm">
                    <li>Vector Embedding</li>
                    <li>HuggingFace</li>
                    <li>MOA</li>
                    <li>Reinforcement Learning from Human Feedback (RLHF)</li>
                    <li>Predictive Models Using Linear Regression</li>
                    <li>AutoGen</li>
                    <li>RAG</li>
                    <li>Agents</li>
                    <li>Kaggle</li>
                    <li>Jupyter</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;